const initialState = {
  sidebarShow: true,
  asideShow: false,
  darkMode: true
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "setSidebar":
      state = {
        ...state,
        sidebarShow: action.payload,
      };
      break;
    case "setAside":
      state = {
        ...state,
        asideShow: action.payload,
      };
      break;
    case "setDarkMode":
      state = {
        ...state,
        darkMode: action.payload,
      };
      break;
    default:
      state = {...state};
      break;
  }
  return state;
};

export default reducer;
