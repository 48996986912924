import { authenticationService } from "../services/authentication.service";

export const checkTokenExpiration = store => next => action => {
    const token = localStorage.getItem("token");
    if (token) {
        if (authenticationService.parseJwt(token))
          if (authenticationService.parseJwt(token).exp < Date.now() / 1000) {
            authenticationService.logout();
            window.location.href = "/login";
            next(action);
          }
    }
    return next(action);
}