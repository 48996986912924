const initialState = {
  feeds: [],
  feed_conf: [],
  feed_promotion_items: [],
  feed_report: [],

  feed_header_result: "",
  feed_map_result: "",
  feed_promotion_result: "",
  feed_promotion_item_result: "",
  feed_include_items_result: "",

  error: "",
  errorStatusCode: null,

  token: localStorage.getItem("token"),
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
		// feeds
		case 'FETCH_FEEDS_SUCCESS':
			state = {
				...state,
				feeds: action.payload,
			};
			break;
		case 'FETCH_FEEDS_FAILURE':
			state = {
				...state,
				error: action.payload.data.error.message,
				errorStatusCode: action.payload.status,
			};
			break;
		// feed configuration
		case 'FETCH_FEED_CONFIG_SUCCESS':
			state = {
				...state,
				feed_conf: action.payload,
			};
			break;
		case 'FETCH_FEED_CONFIG_FAILURE':
			state = {
				...state,
				error: action.payload.data.error.message,
				errorStatusCode: action.payload.status,
			};
			break;
		// add feed headers
		case 'ADD_FEED_HEADER_SUCCESS':
			state = {
				...state,
				feed_header_result: action.payload.result,
			};
			break;
		case 'ADD_FEED_HEADER_FAILURE':
			state = {
				...state,
				error: action.payload.data.error.message,
				errorStatusCode: action.payload.status,
			};
			break;
		// update feed headers
		case 'UPDATE_FEED_HEADER_SUCCESS':
			state = {
				...state,
				feed_header_result: action.payload,
			};
			break;
		case 'UPDATE_FEED_HEADER_FAILURE':
			state = {
				...state,
				error: action.payload.data.error.message,
				errorStatusCode: action.payload.status,
			};
			break;
		// delete feed header
		case 'DELETE_FEED_HEADER_SUCCESS':
			state = {
				...state,
				feed_header_result: action.payload,
			};
			break;
		case 'DELETE_FEED_HEADER_FAILURE':
			state = {
				...state,
				error: action.payload.data.error.message,
				errorStatusCode: action.payload.status,
			};
			break;
		// add feed map
		case 'ADD_FEED_MAP_SUCCESS':
			state = {
				...state,
				feed_map_result: action.payload.result,
			};
			break;
		case 'ADD_FEED_MAP_FAILURE':
			state = {
				...state,
				error: action.payload.data.error.message,
				errorStatusCode: action.payload.status,
			};
			break;
		// update feed map
		case 'UPDATE_FEED_MAP_SUCCESS':
			state = {
				...state,
				feed_map_result: action.payload,
			};
			break;
		case 'UPDATE_FEED_MAP_FAILURE':
			state = {
				...state,
				error: action.payload.data.error.message,
				errorStatusCode: action.payload.status,
			};
			break;
		// delete feed map
		case 'DELETE_FEED_MAP_SUCCESS':
			state = {
				...state,
				feed_map_result: action.payload,
			};
			break;
		case 'DELETE_FEED_MAP_FAILURE':
			state = {
				...state,
				error: action.payload.data.error.message,
				errorStatusCode: action.payload.status,
			};
			break;

		// add feed promotion
		case 'ADD_FEED_PROMOTION_SUCCESS':
			state = {
				...state,
				feed_promotion_result: action.payload.result,
			};
			break;
		case 'ADD_FEED_PROMOTION_FAILURE':
			state = {
				...state,
				error: action.payload.data.error.message,
				errorStatusCode: action.payload.status,
			};
			break;
		// update feed promotion
		case 'UPDATE_FEED_PROMOTION_SUCCESS':
			state = {
				...state,
				feed_promotion_result: action.payload,
			};
			break;
		case 'UPDATE_FEED_PROMOTION_FAILURE':
			state = {
				...state,
				error: action.payload.data.error.message,
				errorStatusCode: action.payload.status,
			};
			break;
		// delete feed promotion
		case 'DELETE_FEED_PROMOTION_SUCCESS':
			state = {
				...state,
				feed_promotion_result: action.payload,
			};
			break;
		case 'DELETE_FEED_PROMOTION_FAILURE':
			state = {
				...state,
				error: action.payload.data.error.message,
				errorStatusCode: action.payload.status,
			};
			break;

		// get feed promotion items
		case 'FETCH_FEED_PROMOTION_ITEMS_SUCCESS':
			state = {
				...state,
				feed_promotion_items: action.payload,
			};
			break;
		case 'FETCH_FEED_PROMOTION_ITEMS_FAILURE':
			state = {
				...state,
				error: action.payload.data.error.message,
				errorStatusCode: action.payload.status,
			};
			break;

		// add feed promotion item
		case 'ADD_FEED_PROMOTION_ITEM_SUCCESS':
			state = {
				...state,
				feed_promotion_item_result: action.payload.result,
			};
			break;
		case 'ADD_FEED_PROMOTION_ITEM_FAILURE':
			state = {
				...state,
				error: action.payload.data.error.message,
				errorStatusCode: action.payload.status,
			};
			break;
		// add feed promotion item
		case 'ADD_FEED_PROMOTION_ITEMS_FILE_SUCCESS':
			state = {
				...state,
				feed_promotion_items_result: action.payload.result,
			};
			break;
		case 'ADD_FEED_PROMOTION_ITEMS_FILE_FAILURE':
			state = {
				...state,
				error: action.payload.data.error.message,
				errorStatusCode: action.payload.status,
			};
			break;
		// update feed promotion item
		case 'UPDATE_FEED_PROMOTION_ITEM_SUCCESS':
			state = {
				...state,
				feed_promotion_item_result: action.payload,
			};
			break;
		case 'UPDATE_FEED_PROMOTION_ITEM_FAILURE':
			state = {
				...state,
				error: action.payload.data.error.message,
				errorStatusCode: action.payload.status,
			};
			break;
		// delete feed promotion item
		case 'DELETE_FEED_PROMOTION_ITEM_SUCCESS':
			state = {
				...state,
				feed_promotion_item_result: action.payload,
			};
			break;
		case 'DELETE_FEED_PROMOTION_ITEM_FAILURE':
			state = {
				...state,
				error: action.payload.data.error.message,
				errorStatusCode: action.payload.status,
			};
			break;
		// feed include items
		case 'FETCH_FEED_INCLUDES_ITEMS_FILE_SUCCESS':
			state = {
				...state,
				feed_include_items_result: action.payload,
			};
			break;
		case 'FETCH_FEED_INCLUDES_ITEMS_FILE_FAILURE':
			state = {
				...state,
				error: action.payload.data.error.message,
				errorStatusCode: action.payload.status,
			};
			break;
		// feeds report
		case 'FETCH_FEED_REPORT_SUCCESS':
			state = {
				...state,
				feed_report: action.payload,
			};
			break;
		case 'FETCH_FEED_REPORT_FAILURE':
			state = {
				...state,
				error: action.payload.data.error.message,
				errorStatusCode: action.payload.status,
			};
			break;
		// feed status update
		case 'UPDATE_FEED_STATUS_SUCCESS':
			state = {
				...state,
				feed_status_result: action.payload,
			};
			break;
		case 'UPDATE_FEED_STATUS_FAILURE':
			state = {
				...state,
				error: action.payload.data.error.message,
				errorStatusCode: action.payload.status,
			};
			break;
		// update feed promotion status
		case 'UPDATE_FEED_PROMOTION_STATUS_SUCCESS':
			state = {
				...state,
				feed_promotion_status_result: action.payload,
			};
			break;
		case 'UPDATE_FEED_PROMOTION_STATUS_FAILURE':
			state = {
				...state,
				error: action.payload.data.error.message,
				errorStatusCode: action.payload.status,
			};
			break;

		default:
			state = { ...state };
			break;
  }
  return state;
};

export default reducer;
