const initialState = {
  error: "",
  errorStatusCode: null,
  token: localStorage.getItem("token"),
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      state = {
        ...state,
        token: action.payload.token,
      };
      break;
    case "LOGIN_FAILURE":
      state = {
        ...state,
        error: action.payload.data.error.message,
        errorStatusCode: action.payload.data.error.errorStatusCode,
      };
      break;
    case "AUTH_USER_UNAUTHORIZED":
      state = {
        ...state,
        error: action.payload.data.error.message,
        errorStatusCode: action.payload.data.error.errorStatusCode,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default loginReducer;
