const initialState = {
  users: [],
  error: "",
  errorStatusCode: null,
  token: localStorage.getItem("token"),
  user: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_USERS_SUCCESS":
      state = {
        ...state,
        users: action.payload,
      };
      break;
    case "FETCH_USERS_FAILURE":
      state = {
        ...state,
        error: action.payload.data.error,
        errorStatusCode: action.payload.status,
        users: action.payload,
      };
      break;
    // FETCH
    case "FETCH_USER_SUCCESS":
      state = {
        ...state,
        user: action.payload,
      };
      break;
    case "FETCH_USER_FAILURE":
      state = {
        ...state,
        fetchingUser: false,
        error: action.payload.data.error,
        errorStatusCode: action.payload.status,
        user: action.payload,
      };
      break;
    // ADD
    case "ADD_USER_SUCCESS":
      state = {
        ...state,
        addingUser: false,
      };
      break;
    case "ADD_USER_FAILURE":
      state = {
        ...state,
        addingUser: false,
        error: action.payload.data.error,
        errorStatusCode: action.payload.status,
      };
      break;
    // EDIT
    case "EDIT_USER_SUCCESS":
      state = {
        ...state,
        editingUser: false,
        user: action.payload,
      };
      break;
    case "EDIT_USER_FAILURE":
      state = {
        ...state,
        editingUser: false,
        error: action.payload.data.error,
        errorStatusCode: action.payload.status,
        user: action.payload,
      };
      break;
    // DELETE
    case "DELETE_USER_SUCCESS":
      state = {
        ...state,
        deletingUser: false,
        users: action.payload,
      };
      break;
    case "DELETE_USER_FAILURE":
      state = {
        ...state,
        deletingUser: false,
        error: action.payload.data.error,
        errorStatusCode: action.payload.status,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default reducer;
