const start_date = () => {
  const date = new Date();
  const month = String(date.getMonth() + 1).padStart(2, 0);
  const day = "01";

  return `${date.getFullYear()}-${month}-${day}`;
};

const end_date = () => {
  const date = new Date();
  const month = String(date.getMonth() + 1).padStart(2, 0);
  const day = String(date.getDate()).padStart(2, 0);

  return `${date.getFullYear()}-${month}-${day}`;
};

const initialState = {
  stores: [],
  start_date: start_date(),
  end_date: end_date(),
  channel: [],
  country: [],

  error: "",
  errorStatusCode: null,

  token: localStorage.getItem("token"),
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_DATE_SUCCESS":
      state = {
        ...state,
        error: "",
        errorStatusCode: null,
        start_date: action.payload.start_date,
        end_date: action.payload.end_date,
        channel: action.payload.channel,
        country: action.payload.country,
      };
      break;
    case "UPDATE_DATE_FAILURE":
      state = {
        ...state,
        error: action.payload.data.error.message,
        errorStatusCode: action.payload.status,
      };
      break;
    // stores
    case "FETCH_STORES_SUCCESS":
      state = {
        ...state,
        error: "",
        errorStatusCode: null,
        stores: action.payload,
      };
      break;
    case "FETCH_STORES_FAILURE":
      state = {
        ...state,
        error: action.payload.data.error.message,
        errorStatusCode: action.payload.status,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default reducer;
