const initialState = {
	loadingGraph: true,
	loadingPreviousGraph: true,
	loadingSummary: true,
	loadingPreviousSummary: true,
	loadingBestsellers: true,
	loadingDetailedSummary: true,
	loadingPreviousDetailedSummary: true,
	loadingUncaptured: true,
	loadingUncapturedStore: true,
	loadingHighValue: true,
	loadingReports: true,

	stats: [],
	previousStats: [],
	summaryData: {},
	previousSummaryData: {},
	detailedSummaryData: [],
	previousDetailedSummaryData: [],
	bestsellersData: [],
	uncapturedData: [],
	uncapturedStoreData: [],
	highValueOrders: [],

	reports: {},

	error: '',
	errorStatusCode: null,

	token: localStorage.getItem('token'),
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		// graph stats
		case 'FETCH_GRAPH_STATS_START':
			state = {
				...state,
			};
			break;
		case 'FETCH_GRAPH_STATS_SUCCESS':
			state = {
				...state,
				loadingGraph: false,
				error: '',
				errorStatusCode: null,
				stats: action.payload,
			};
			break;
		case 'FETCH_GRAPH_STATS_FAILURE':
			state = {
				...state,
				loadingGraph: false,
				error: action.payload.data.error.message,
				errorStatusCode: action.payload.status,
			};
			break;
		// previous graph stats
		case 'FETCH_PREVIOUS_GRAPH_STATS_START':
			state = {
				...state,
			};
			break;
		case 'FETCH_PREVIOUS_GRAPH_STATS_SUCCESS':
			state = {
				...state,
				loadingPreviousGraph: false,
				error: '',
				errorStatusCode: null,
				previousStats: action.payload,
			};
			break;
		case 'FETCH_PREVIOUS_GRAPH_STATS_FAILURE':
			state = {
				...state,
				loadingPreviousGraph: false,
				error: action.payload.data.error.message,
				errorStatusCode: action.payload.status,
			};
			break;
		// summary
		case 'FETCH_SUMMARY_START':
			state = {
				...state,
			};
			break;
		case 'FETCH_SUMMARY_SUCCESS':
			state = {
				...state,
				loadingSummary: false,
				summaryData: action.payload,
			};
			break;
		case 'FETCH_SUMMARY_FAILURE':
			state = {
				...state,
				loadingSummary: false,
				error: action.payload.data.error.message,
				errorStatusCode: action.payload.status,
			};
			break;
		// previous summary
		case 'FETCH_PREVIOUS_SUMMARY_START':
			state = {
				...state,
			};
			break;
		case 'FETCH_PREVIOUS_SUMMARY_SUCCESS':
			state = {
				...state,
				loadingPreviousSummary: false,
				previousSummaryData: action.payload,
			};
			break;
		case 'FETCH_PREVIOUS_SUMMARY_FAILURE':
			state = {
				...state,
				loadingPreviousSummary: false,
				error: action.payload.data.error.message,
				errorStatusCode: action.payload.status,
			};
			break;
		// bestsellers
		case 'FETCH_BESTSELLERS_START':
			state = {
				...state,
			};
			break;
		case 'FETCH_BESTSELLERS_SUCCESS':
			state = {
				...state,
				loadingBestsellers: false,
				bestsellersData: action.payload,
			};
			break;
		case 'FETCH_BESTSELLERS_FAILURE':
			state = {
				...state,
				loadingBestsellers: false,
				error: action.payload.data.error.message,
				errorStatusCode: action.payload.status,
			};
			break;
		// detailed summary
		case 'FETCH_DETAILED_SUMMARY_START':
			state = {
				...state,
			};
			break;
		case 'FETCH_DETAILED_SUMMARY_SUCCESS':
			state = {
				...state,
				loadingDetailedSummary: false,
				detailedSummaryData: action.payload,
			};
			break;
		case 'FETCH_DETAILED_SUMMARY_FAILURE':
			state = {
				...state,
				loadingDetailedSummary: false,
				error: action.payload.data.error.message,
				errorStatusCode: action.payload.status,
			};
			break;
		// previous detailed summary
		case 'FETCH_PREVIOUS_DETAILED_SUMMARY_START':
			state = {
				...state,
			};
			break;
		case 'FETCH_PREVIOUS_DETAILED_SUMMARY_SUCCESS':
			state = {
				...state,
				loadingPreviousDetailedSummary: false,
				previousDetailedSummaryData: action.payload,
			};
			break;
		case 'FETCH_PREVIOUS_DETAILED_SUMMARY_FAILURE':
			state = {
				...state,
				loadingPreviousDetailedSummary: false,
				error: action.payload.data.error.message,
				errorStatusCode: action.payload.status,
			};
			break;
		// uncaptured orders
		case 'FETCH_UNCAPTURED_START':
			state = {
				...state,
			};
			break;
		case 'FETCH_UNCAPTURED_SUCCESS':
			state = {
				...state,
				loadingUncaptured: false,
				error: '',
				errorStatusCode: null,
				uncapturedData: action.payload,
			};
			break;
		case 'FETCH_UNCAPTURED_FAILURE':
			state = {
				...state,
				loadingUncaptured: false,
				error: action.payload.data.error.message,
				errorStatusCode: action.payload.status,
			};
			break;
		// uncaptured orders by store
		case 'FETCH_UNCAPTURED_STORE_START':
			state = {
				...state,
			};
			break;
		case 'FETCH_UNCAPTURED_STORE_SUCCESS':
			state = {
				...state,
				loadingUncapturedStore: false,
				error: '',
				errorStatusCode: null,
				uncapturedStoreData: action.payload,
			};
			break;
		case 'FETCH_UNCAPTURED_STORE_FAILURE':
			state = {
				...state,
				loadingUncapturedStore: false,
				error: action.payload.data.error.message,
				errorStatusCode: action.payload.status,
			};
			break;
		// high value orders
		case 'FETCH_HIGH_VALUE_START':
			state = {
				...state,
			};
			break;
		case 'FETCH_HIGH_VALUE_SUCCESS':
			state = {
				...state,
				loadingHighValue: false,
				error: '',
				errorStatusCode: null,
				highValueOrders: action.payload,
			};
			break;
		case 'FETCH_HIGH_VALUE_FAILURE':
			state = {
				...state,
				loadingHighValue: false,
				error: action.payload.data.error.message,
				errorStatusCode: action.payload.status,
			};
			break;

		// reports
		case 'FETCH_REPORTS_START':
			state = {
				...state,
			};
			break;
		case 'FETCH_REPORTS_SUCCESS':
			state = {
				...state,
				loadingReports: false,
				reports: action.payload,
			};
			break;
		case 'FETCH_REPORTS_FAILURE':
			state = {
				...state,
				loadingReports: false,
				error: action.payload.data.error.message,
				errorStatusCode: action.payload.status,
			};
			break;

		default:
			state = { ...state };
			break;
	}
	return state;
};

export default reducer;
