const initialState = {
  tickets: [],
  ticket: {},

  error: "",
  errorStatusCode: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
		case 'FETCH_ZENDESK_ARCHIVE_SUCCESS':
			state = {
				...state,
				error: '',
				errorStatusCode: null,
				tickets: action.payload,
			};
			break;
		case 'FETCH_ZENDESK_ARCHIVE_FAILURE':
			state = {
				...state,
				error: action.payload.data.error.message,
				errorStatusCode: action.payload.status,
			};
			break;
		case 'FETCH_ZENDESK_ARCHIVE_TICKET_SUCCESS':
			state = {
				...state,
				error: '',
				errorStatusCode: null,
				ticket: action.payload,
			};
			break;
		case 'FETCH_ZENDESK_ARCHIVE_TICKET_FAILURE':
			state = {
				...state,
				error: action.payload.data.error.message,
				errorStatusCode: action.payload.status,
			};
			break;
		default:
			state = { ...state };
			break;
  }
  return state;
};

export default reducer;
