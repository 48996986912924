const initialState = {
	orders: [],
	search_orders: [],
	order: {},
	gift_cards: [],

	error: '',
	errorStatusCode: null,

	token: localStorage.getItem('token'),
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		// orders
		case 'FETCH_ORDERS_SUCCESS':
			state = {
				...state,
				orders: action.payload,
			};
			break;
		case 'FETCH_ORDERS_FAILURE':
			state = {
				...state,
				error: action.payload.data.error.message,
				errorStatusCode: action.payload.status,
			};
			break;
		// order
		case 'FETCH_ORDER_SUCCESS':
			state = {
				...state,
				order: action.payload,
			};
			break;
		case 'FETCH_ORDER_FAILURE':
			state = {
				...state,
				error: action.payload.data.error.message,
				errorStatusCode: action.payload.status,
			};
			break;
		// search orders
		case 'FETCH_SEARCH_ORDERS_SUCCESS':
			state = {
				...state,
				search_orders: action.payload,
				error: '',
			};
			break;
		case 'FETCH_SEARCH_ORDERS_FAILURE':
			state = {
				...state,
				orders: [],
				error: action.payload.data.error.message,
				errorStatusCode: action.payload.status,
			};
			break;
		// gift card
		case 'FETCH_GIFT_CARDS_SUCCESS':
			state = {
				...state,
				gift_cards: action.payload,
			};
			break;
		case 'FETCH_GIFT_CARDS_FAILURE':
			state = {
				...state,
				error: action.payload.data.error.message,
				errorStatusCode: action.payload.status,
			};
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

export default reducer;
