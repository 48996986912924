import { createStore, compose, applyMiddleware } from "redux";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";

// Middleware
import { logger } from "redux-logger";
import thunk from "redux-thunk";
import { checkTokenExpiration } from "./middleware/check-token-expiration";

import createRootReducer from "./reducers";

export const history = createBrowserHistory();

// const initialState = {
//   sidebarShow: "responsive",
//   asideShow: false,
//   darkMode: true,
// };

// const changeState = (state = initialState, { type, ...rest }) => {
//   switch (type) {
//     case "set":
//       return { ...state, ...rest };
//     default:
//       return state;
//   }
// };

export default function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    compose(
      applyMiddleware(
        // changeState,
        checkTokenExpiration,
        routerMiddleware(history), // for dispatching history actions
        thunk,
        logger
      )
    )
  );

  return store;
}
