import { BehaviorSubject } from "rxjs";

const currentUserSubject = new BehaviorSubject();

export const authenticationService = {
  logout,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.getValue();
  },
  parseJwt(token) {
    if (!token) {
      return;
    }
    const base64Url = token.split(".")[1];
    if (base64Url) {
      const base64 = base64Url.replace("-", "+").replace("_", "/");
      return JSON.parse(window.atob(base64));
    }
  },
};

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("token");
  localStorage.removeItem('pinToken');
  currentUserSubject.next(null);
}
