export const Role = {
  Owner: "Owner",
  Admin: "Admin",
  Backoffice: "Backoffice",
  Marketing: "Marketing",
  Sales: "Sales",
  Design: "Design",
  API: "API",
  External: "External",
  IT: "IT",
};

// Backoffice:
// /orders
// /items
// /competitors

// Marketing:
// /items
// /promotions

// Sales:
// /items
// /competitors
// /promotions

// External:
// /orders/search
// /orders/:order_number/view