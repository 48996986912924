const initialState = {
  promotions: [],
  promotion_items: [],
  promotion: [],

  error: "",
  errorStatusCode: null,

  token: localStorage.getItem("token"),
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "USER_UNAUTHORIZED":
      state = {
        ...state,
        loggingIn: false,
        error: action.payload.data.error,
        errorStatusCode: action.payload.status,
      };
      break;
    // promotions
    case "FETCH_PROMOTIONS_SUCCESS":
      state = {
        ...state,
        promotions: action.payload,
      };
      break;
    case "FETCH_PROMOTIONS_FAILURE":
      state = {
        ...state,
        promotions: [],
        error: action.payload.data.error.message,
        errorStatusCode: action.payload.status,
      };
      break;
    // promotion items
    case "FETCH_PROMOTION_ITEMS_SUCCESS":
      state = {
        ...state,
        promotion_items: action.payload,
      };
      break;
    case "FETCH_PROMOTION_ITEMS_FAILURE":
      state = {
        ...state,
        promotion_items: [],
        error: action.payload.data.error.message,
        errorStatusCode: action.payload.status,
      };
      break;
    // promotion products
    case "FETCH_PROMOTION_SUCCESS":
      state = {
        ...state,
        promotion: action.payload,
      };
      break;
    case "FETCH_PROMOTION_FAILURE":
      state = {
        ...state,
        promotion: [],
        error: action.payload.data.error.message,
        errorStatusCode: action.payload.status,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default reducer;
