import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import auth from "./loginReducer";
import global from "./globalReducer";

import users from "./usersReducer";
import reports from "./reportsReducer";
import orders from "./ordersReducer";

import items from "./itemsReducer";

import feeds from "./feedsReducer";

import promotions from "./promotionsReducer";

import warehouse from "./warehouseReducer";

import customers from "./customersReducer";

import prices from "./pricesReducer";

// import stats from "./statsReducer";
// import competitors from "./competitorsReducer";
// import customers from "./customersReducer";

// import ga from "./gaReducer";
// import watchlog from "./watchlogReducer";

// import marketplaces from "./marketplacesReducer";

// import tracker from "./trackerReducer";


// import mercury from "./mercuryReducer";
import theme from "./themeReducer";

import zendesk from './zendeskReducer';

const createRootReducer = (history) =>
	combineReducers({
		router: connectRouter(history),
		auth,
		global,
		users,
		reports,
		orders,
		warehouse,
		customers,
		prices,

		theme,
		feeds,
		items,
		promotions,

		zendesk,

		// stats,
		// competitors,
		// ga,
		// watchlog,
		// marketplaces,
		// tracker,
	});

export default createRootReducer;
