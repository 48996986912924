import React from "react";
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";

// routes config
import routes from "./routes";
import PrivateRoute from "./routes/PrivateRoute";

import "./scss/style.scss";
import "spinkit/spinkit.min.css";

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const App = ({ history }) => {
  return (
    <ConnectedRouter history={history}>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route exact path="/login" name="Login Page" component={Login} />
          <TheLayout>
            {routes.map((route, idx) => {
              return <PrivateRoute key={idx} path={route.path} exact={route.exact} name={route.name} roles={route.roles} route={route} />;
            })}
            {/* <Redirect from="/" to="/dashboard" /> */}
          </TheLayout>
        </Switch>
      </React.Suspense>
    </ConnectedRouter>
  );
};

export default App;
