const initialState = {
	pinToken: localStorage.getItem('pinToken'),
	data: [],

	error: '',
	errorStatusCode: null,

	token: localStorage.getItem('token'),
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case 'USER_UNAUTHORIZED':
			state = {
				...state,
				loggingIn: false,
				error: action.payload.data.error.message,
				errorStatusCode: action.payload.status,
				fetchingPriceItem: false,
			};
			break;
		// prices auth
		case 'FETCH_AUTH_PRICES_SUCCESS':
			state = {
				...state,
				data: action.payload.data,
				pinToken: localStorage.getItem('pinToken'),
				error: '',
			};
			break;
		case 'FETCH_AUTH_PRICES_FAILURE':
			state = {
				...state,
				error: action.payload.data.error.message,
				errorStatusCode: action.payload.status,
			};
			break;
		// prices
		case 'FETCH_PRICES_SUCCESS':
			state = {
				...state,
				prices: action.payload.data,
				error: '',
			};
			break;
		case 'FETCH_PRICES_FAILURE':
			state = {
				...state,
				error: action.payload.data.error.message,
				errorStatusCode: action.payload.status,
			};
			break;
		// remove prices timer
		case 'REMOVE_PRICES':
			state = {
				...state,
				prices: action.payload,
			};
			break;

		default:
			state = { ...state };
			break;
	}
	return state;
};

export default reducer;
