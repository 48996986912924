const initialState = {
	beans_history: [],

	error: '',
	errorStatusCode: null,

	token: localStorage.getItem('token'),
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case 'USER_UNAUTHORIZED':
			state = {
				...state,
				loggingIn: false,
				error: action.payload.data.error,
				errorStatusCode: action.payload.status,
				fetchingPriceItem: false,
			};
			break;
		// customer beans
		case 'FETCH_CUSTOMER_BEANS_SUCCESS':
			state = {
				...state,
				beans_history: action.payload,
				error: ''
			};
			break;
		case 'FETCH_CUSTOMER_BEANS_FAILURE':
			state = {
				...state,
				beans_history: [],
				error: action.payload.data.error.message,
				errorStatusCode: action.payload.status,
			};
			break;

		default:
			state = { ...state };
			break;
	}
	return state;
};

export default reducer;
