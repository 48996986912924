import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import { authenticationService } from "../services/authentication.service";
import { CFade } from "@coreui/react";

const PrivateRoute = ({ component: Component, roles, token, route, errorStatusCode, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {

      if (!token)
        // not logged in so redirect to login page with the return url
        return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;

      let currentUser = authenticationService.parseJwt(token);

      if (!currentUser)
        // not logged in so redirect to login page with the return url
        return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;

      // check if route is restricted by role
      if ((roles && roles.indexOf(currentUser.role) === -1) || [401, 403].indexOf(errorStatusCode) !== -1) {
        // role not authorised so redirect to home page
        return <Redirect to={{ pathname: "/not-authorized" }} />;
      }

      // authorised so return component
      return (
        <CFade>
          <route.component {...props} />
        </CFade>
      );
    }}
  />
);

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    errorStatusCode: state.auth.errorStatusCode || state.users.errorStatusCode,
  };
};

export default connect(mapStateToProps, {})(PrivateRoute);
