const initialState = {
  summary: [],
  warehouses: [],

  error: "",
  errorStatusCode: null,

  token: localStorage.getItem("token"),
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "USER_UNAUTHORIZED":
      state = {
        ...state,
        loggingIn: false,
        error: action.payload.data.error,
        errorStatusCode: action.payload.status,
        fetchingPriceItem: false,
      };
      break;
    // warehouses
    case "FETCH_WAREHOUSES_SUCCESS":
      state = {
        ...state,
        warehouses: action.payload,
      };
      break;
    case "FETCH_WAREHOUSES_FAILURE":
      state = {
        ...state,
        error: action.payload.data.error.message,
        errorStatusCode: action.payload.status,
      };
      break;
    // summary
    case "FETCH_WAREHOUSE_SUMMARY_SUCCESS":
      state = {
        ...state,
        summary: action.payload,
      };
      break;
    case "FETCH_WAREHOUSE_SUMMARY_FAILURE":
      state = {
        ...state,
        error: action.payload.data.error.message,
        errorStatusCode: action.payload.status,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default reducer;
